import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set session persistence to maintain user session across redirects
try {
  setPersistence(auth, browserSessionPersistence)
    .then(() => {
      console.log("Firebase auth persistence set to browserSessionPersistence");
    })
    .catch((error) => {
      console.error("Error setting Firebase auth persistence:", error);
    });
} catch (error) {
  console.error("Unexpected error setting persistence:", error);
}

const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };