import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Added useLocation
import { motion } from "framer-motion";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, collection, getDocs, getDoc, onSnapshot, query, orderBy } from "firebase/firestore";
import "../styles.css";

const SkillsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Added to check URL params
  const [skillsData, setSkillsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [unlockedSkill, setUnlockedSkill] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [dogs, setDogs] = useState([]);
  const [ratings, setRatings] = useState({});
  const [hoveredSkill, setHoveredSkill] = useState(null);

  const categoryMappings = useMemo(() => ({
    'basic_commands___cues': 'Basic Skills',
    'behavioral_training_&_social_skills': 'Behavioral Skills',
    'advanced_obedience_&_manners': 'Advanced Skills',
    'tricks_&_fun_commands': 'Fun Skills'
  }), []);

  useEffect(() => {
    console.log("SkillsScreen loaded. URL:", location.pathname + location.search); // Debug URL
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) {
        console.log("No user authenticated, redirecting to /");
        navigate('/');
      } else {
        console.log("Authenticated user UID:", user.uid);
        const userDocRef = doc(firestore, 'users', user.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            console.log("Firestore snapshot data:", JSON.stringify(data, null, 2));
            console.log("isSubscribed from snapshot:", data.isSubscribed);
            setIsSubscribed(data.isSubscribed || false);
            fetchUserData(user.uid).catch((err) => {
              console.error("Error fetching user data:", err);
              setError('Error loading user data: ' + err.message);
            });
          } else {
            console.warn("User document missing for UID:", user.uid);
            setError('User data not found.');
          }
        }, (err) => {
          console.error("Firestore onSnapshot error:", err);
          setError('Error listening to user data: ' + err.message);
        });

        // Polling with debug
        const pollSubscription = setInterval(async () => {
          console.log("Polling Firestore for UID:", user.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Polling - Full Firestore data:", JSON.stringify(data, null, 2));
            console.log("Polling - isSubscribed:", data.isSubscribed);
            setIsSubscribed(data.isSubscribed || false);
          } else {
            console.log("Polling - No document found for UID:", user.uid);
          }
        }, 5000);

        return () => {
          console.log("Cleaning up listeners for UID:", user.uid);
          unsubscribeDoc();
          clearInterval(pollSubscription);
        };
      }
    });

    return () => {
      console.log("Unmounting SkillsScreen, cleaning up auth listener");
      unsubscribeAuth();
    };
  }, [navigate, location]);

  const fetchUserData = async (uid) => {
    try {
      const dogsRef = collection(firestore, 'users', uid, 'dogs');
      const dogsSnapshot = await getDocs(dogsRef);
      const dogsList = dogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDogs(dogsList);

      if (dogsList.length > 0) {
        const firstDogId = dogsList[0].id;
        const ratingsRef = collection(firestore, 'users', uid, 'dogs', firstDogId, 'skillRatings');
        const ratingsSnapshot = await getDocs(ratingsRef);
        const ratingsData = {};
        ratingsSnapshot.forEach(doc => {
          const rating = doc.data();
          ratingsData[rating.skillId] = rating.rating || 0;
        });
        setRatings(ratingsData);

        if (!ratingsSnapshot.empty) {
          const firstRating = ratingsSnapshot.docs[0].data();
          setUnlockedSkill({ 
            category: firstRating.categoryId, 
            skillId: firstRating.skillId, 
            skillName: firstRating.skillName 
          });
        }
      }
    } catch (err) {
      console.error("Error fetching dogs or ratings:", err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        setLoading(true);
        const skillsRef = collection(firestore, 'skills');
        const skillsSnapshot = await getDocs(query(skillsRef, orderBy('order')));
        const skillsDataTemp = {};

        for (const categoryDoc of skillsSnapshot.docs) {
          const categoryName = categoryDoc.id;
          const skillsQuery = collection(firestore, `skills/${categoryName}/skills`);
          const skillsSnapshot = await getDocs(query(skillsQuery, orderBy('order')));
          skillsDataTemp[categoryName] = {
            order: categoryDoc.data().order || 0,
            skills: skillsSnapshot.docs.map(skillDoc => ({ id: skillDoc.id, ...skillDoc.data() }))
          };
        }

        const renamedSkillsData = {};
        Object.keys(categoryMappings).forEach((firestoreKey) => {
          const displayName = categoryMappings[firestoreKey];
          renamedSkillsData[displayName] = skillsDataTemp[firestoreKey] || { skills: [] };
        });
        setSkillsData(renamedSkillsData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching skills:", err);
        setError('Failed to load skills: ' + err.message);
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchSkills();
    }
  }, [currentUser, categoryMappings]);

  const generatePersonalizedPlan = () => {
    if (!dogs.length || !Object.keys(skillsData).length) return [];
    const dog = dogs[0];
    const recommendedSkills = [];
    const allSkills = Object.values(skillsData).flatMap(category => category.skills);

    const addSkill = (skillName, reason, category) => {
      const skill = allSkills.find(s => s.name.toLowerCase() === skillName.toLowerCase());
      if (skill && !recommendedSkills.some(rs => rs.skill.id === skill.id)) {
        recommendedSkills.push({ skill, reason });
      }
    };

    if (dog.pottyTrained === "No") addSkill("wait", "For potty training (wait at door)", "basic_commands___cues");
    const behaviorMap = {
      "Aggression": ["settle", "leave_it"],
      "Separation Anxiety": ["stay", "settle"],
      "Excessive Barking": ["quiet", "speak"],
      "Jumping": ["off", "sit"],
      "Chewing": ["leave_it", "drop_it"],
      "Pulling on leash": ["leash_walking"],
      "Counter-surfing": ["no_counter-surfing"],
      "Shyness": ["accept_handling", "greet_politely"]
    };
    dog.behavioralIssues?.forEach(issue => {
      behaviorMap[issue]?.forEach(skillName => addSkill(skillName, `To address ${issue.toLowerCase()}`, "behavioral_training_&_social_skills"));
    });

    const basicSkills = ["sit", "stay", "come"];
    basicSkills.forEach(skillName => {
      if (!dog.skills?.includes(skillName)) addSkill(skillName, "Essential basic skill", "basic_commands___cues");
    });

    const goalMap = {
      "Obedience (e.g., sit, stay)": ["heel", "wait"],
      "Behavior (e.g., less barking)": ["quiet", "impulse_control"],
      "Fun tricks (e.g., roll over)": ["roll_over", "fetch"],
      "Social skills (e.g., greeting politely)": ["greet_politely", "accept_handling"]
    };
    if (recommendedSkills.length < 5 && dog.trainingGoal && goalMap[dog.trainingGoal]) {
      goalMap[dog.trainingGoal].forEach(skillName => {
        if (!dog.skills?.includes(skillName)) addSkill(skillName, `Matches your goal: ${dog.trainingGoal.toLowerCase()}`, "basic_commands___cues");
      });
    }

    const breedMap = {
      "Labrador Retriever": ["fetch", "spin"],
      "Border Collie": ["find_it", "place"],
      "Bulldog": ["sit", "shake"]
    };
    if (recommendedSkills.length < 5 && breedMap[dog.dogBreed]) {
      breedMap[dog.dogBreed].forEach(skillName => {
        if (!dog.skills?.includes(skillName)) addSkill(skillName, `Great for ${dog.dogBreed}s`, "tricks_&_fun_commands");
      });
    }

    return recommendedSkills.slice(0, 6);
  };

  const handleSkillPress = async (skill, categoryName) => {
    const firestoreCategoryKey = Object.keys(categoryMappings).find(
      (key) => categoryMappings[key] === categoryName
    ) || skill.category;

    if (!firestoreCategoryKey) return;

    const skillData = { category: firestoreCategoryKey, skillId: skill.id };

    console.log("Handling skill press - isSubscribed:", isSubscribed); // Debug access logic
    if (isSubscribed) {
      navigateToTrainer(skillData, skill.name);
    } else if (!unlockedSkill && dogs.length > 0) {
      const firstDogId = dogs[0].id;
      try {
        await setDoc(
          doc(firestore, 'users', currentUser.uid, 'dogs', firstDogId, 'skillRatings', skill.id),
          { 
            categoryId: firestoreCategoryKey, 
            skillId: skill.id, 
            skillName: skill.name,
            rating: 0,
            badgeEarned: false,
            timestamp: new Date().toISOString()
          },
          { merge: true }
        );
        setUnlockedSkill({ category: firestoreCategoryKey, skillId: skill.id, skillName: skill.name });
        navigateToTrainer(skillData, skill.name);
      } catch (err) {
        console.error("Error unlocking skill:", err);
        setError('Failed to unlock skill: ' + err.message);
      }
    } else if (
      unlockedSkill?.category === firestoreCategoryKey &&
      unlockedSkill?.skillId === skill.id
    ) {
      navigateToTrainer(skillData, skill.name);
    } else {
      navigate('/subscription');
    }
  };

  const navigateToTrainer = (skillData, skillName) => {
    navigate(
      `/trainer?category=${skillData.category}&skill_id=${skillData.skillId}&skill_name=${encodeURIComponent(skillName)}`
    );
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? "star-filled" : "star-empty"}>★</span>
      );
    }
    return stars;
  };

  const renderSkillItem = ({ item: skill, categoryName }) => (
    <motion.div
      className="skill-item"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.button
        className={`skill-circle ${hoveredSkill === skill.id ? "skill-circle-hover" : ""} ${unlockedSkill?.skillId === skill.id ? "skill-circle-unlocked" : ""}`}
        onMouseEnter={() => setHoveredSkill(skill.id)}
        onMouseLeave={() => setHoveredSkill(null)}
        onClick={() => handleSkillPress(skill, categoryName)}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
        aria-label={`Train ${skill.name} skill`}
      >
        <span className="skill-icon">{skill.icon || '🐾'}</span>
        <span className="skill-label">{skill.name}</span>
      </motion.button>
      <p className="skill-desc">{skill.description || 'No description available'}</p>
      {ratings[skill.id] !== undefined && (
        <div className="rating-row">{renderStars(ratings[skill.id])}</div>
      )}
    </motion.div>
  );

  const renderPersonalizedPlan = () => {
    const plan = generatePersonalizedPlan();
    if (!plan.length) {
      return <p className="no-plan-text">No personalized plan yet—add more details to {dogs[0]?.dogName}’s profile!</p>;
    }

    return (
      <motion.div
        className="personalized-plan-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="personalized-plan-title">Personalized Training Plan</h2>
        <p className="personalized-plan-subtitle">
          Tailored for {dogs[0]?.dogName}’s wag-tastic journey!
        </p>
        <div className="plan-grid">
          {plan.map(({ skill, reason }, index) => (
            <motion.div
              key={index}
              className="plan-item"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <motion.button
                className={`plan-skill-circle ${hoveredSkill === skill.id ? "skill-circle-hover" : ""} ${ratings[skill.id] !== undefined ? "plan-skill-circle-progress" : ""}`}
                onMouseEnter={() => setHoveredSkill(skill.id)}
                onMouseLeave={() => setHoveredSkill(null)}
                onClick={() => handleSkillPress(skill, categoryMappings[skill.category] || "Basic Skills")}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                aria-label={`Train ${skill.name} skill`}
              >
                <span className="skill-icon">{skill.icon || '🦴'}</span>
                <span className="skill-label">{skill.name}</span>
              </motion.button>
              <p className="plan-reason">{reason}</p>
              {ratings[skill.id] !== undefined && (
                <div className="plan-progress">{renderStars(ratings[skill.id])}</div>
              )}
            </motion.div>
          ))}
        </div>
      </motion.div>
    );
  };

  const getSubtitle = () => {
    if (isSubscribed && dogs.length > 0) {
      return `Woof! Just 5-10 minutes a day can turn ${dogs[0].dogName} into a star pup!`;
    } else if (unlockedSkill && dogs.length > 0) {
      return (
        <>
          <br />
          You’ve unlocked a free skill—time to wag into training with {dogs[0].dogName}!<br />
          Your free skill: <strong>{unlockedSkill.skillName}</strong>
        </>
      );
    } else {
      return "Pick any skill for free and start your tail-wagging adventure!";
    }
  };

  if (!currentUser) return null;
  if (loading) return <p className="loading">Fetching {dogs[0]?.dogName}’s skills...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="skills-container">
      <motion.div
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="hero-title">Learn the Doglingo!</h1>
        <p className="hero-subtitle">{getSubtitle()}</p>
        <span className="paw-print top-left">🐾</span>
        <span className="paw-print bottom-right">🐾</span>
      </motion.div>
      <div className="content-wrapper">
        {renderPersonalizedPlan()}
        <hr className="section-divider" />
        {Object.entries(skillsData).map(([categoryName, categoryData], index) => (
          <React.Fragment key={categoryName}>
            <motion.div
              className="category-container"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h2 className="category-title">{categoryName}</h2>
              <div className="web-category-layout">
                {categoryData?.skills.length > 0 ? (
                  categoryData.skills.map((skill) => (
                    <div key={skill.id}>
                      {renderSkillItem({ item: skill, categoryName })}
                    </div>
                  ))
                ) : (
                  <p className="no-skills-text">No skills here yet—stay tuned!</p>
                )}
              </div>
            </motion.div>
            {index < Object.entries(skillsData).length - 1 && <hr className="section-divider" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SkillsScreen;